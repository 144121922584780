import { Box, Button, Card, Dialog, Grid, Stack, Typography } from '@mui/material';
import './App.css';
import SendIcon from '@mui/icons-material/Send';
import { AwesomeButton } from 'react-awesome-button';
import 'react-awesome-button/dist/styles.css';
import { useState } from 'react';

function openInNewTab(url: string) {
  try {
    //@ts-ignore
    window.open(url, '_blank').focus();
  }
  catch(e) {

  }
}

function App() {

  const [portfolioKey, setPortfolioKey] = useState<any>(undefined);

  const renderHeaderBackground = () => {
    return <Stack justifyContent={"center"} direction="row">
      <div style={{
        position: 'relative'
      }}>
        {/* <img style={{ width: '350px' }} src="logo.png" alt="logo" /> */}
        <img style={{maxWidth: '450px', width: '100%', marginTop: '30px', marginBottom: '30px'}} src="banner.png" alt="banner" />
        <img style={{ width: '150px', position: 'absolute', bottom: '-30px', right: '-30px' }} src="logo.png" alt="logo" />
      </div>
    </Stack>
  }

  const renderFeatureCard = (content: string) => {
    return <Grid sx={{ padding: '5px' }} xs={12} sm={4}>
      <Card className='featureCard' sx={{ padding: '20px', borderBottom: '4px solid #727D71' }}>
        <Stack justifyContent="center" sx={{ width: '100%', height: '100%' }} direction="row">
          <Stack justifyContent="center" sx={{ width: '100%', height: '100%' }} direction={"column"}>
            <Typography fontSize={"2.0rem"} textAlign={"center"}>{content}</Typography>
          </Stack>
        </Stack>
      </Card>
    </Grid>
  }

  const renderFeatures = () => {
    return <Stack direction="column">
      <Typography sx={{ marginBottom: '30px' }} variant='h4'>Services</Typography>
      <Grid sx={{ width: '100%', maxWidth: '100%', position: 'relative', left: '15px' }} container spacing={2}>
        {renderFeatureCard("Technology that drives business")}
        {renderFeatureCard("Custom Software Fitting any Budget")}
        {renderFeatureCard("Sleek Mobile Friendly Websites")}
        {renderFeatureCard("Frontend and Backend")}
        {renderFeatureCard("Software Optimization")}
        {renderFeatureCard("AWS based infrastructure")}
      </Grid>
    </Stack>
  }

  // const renderBio = () => {
  //   return <Stack direction="row" sx={{ marginTop: '50px', marginBottom: '20px' }} justifyContent={"center"}>
  //     <Typography sx={{ width: '400px', border: '3px solid #77567A', borderRadius: '9px', padding: '10px', fontSize: '1.1rem', fontFamily: '"Roboto"' }} justifyContent={"center"}>
  //       We're happy to work with you on any size software development project. Providing front-end and back-end services. We're experienced starting from scratch and working with your requirements or jumping into an existing project and making any modifications necessary.
  //     </Typography>
  //   </Stack>
  // }

  const renderFeatureBox = () => {
    return <Box sx={{
      marginTop: '70px', height: '80px', borderRadius: '9px', width: 'calc(100% - 6px)', border: '3px solid #77567A', marginBottom: '70px', boxShadow: ' inset 0 0 10px rgba(0,0,0,0.7)',
      background: 'linear-gradient(90deg, rgba(5,5,23,1) 0%, rgba(20,20,57,1) 100%)'
    }}></Box>
  }

  const renderContact = () => {
    return <Stack direction="row" justifyContent={"center"}>
      <AwesomeButton onPress={() => {
        window.open("mailto:justin@barnyak.com", '_blank', 'noopener,noreferrer')
      }}><SendIcon /><span style={{ marginLeft: '6px' }}>Contact</span></AwesomeButton>
    </Stack>
  }

  const renderHero = () => {
    return <Stack sx={{ marginBottom: '40px' }} direction="column">
      <Typography variant="h3" textAlign={"right"}>Transforming Ideas into Reality with Expert React Front-End Development</Typography>
      <Typography variant="h5" textAlign={"right"}>Bespoke Solutions Tailored for Your Business Needs</Typography>
    </Stack>
  }

  const renderMedia = () => {
    return <Stack direction="column" sx={{ marginTop: '30px' }}>
      <Typography sx={{ marginBottom: '30px' }} variant='h4'>Socials</Typography>
      <Stack direction="row" justifyContent={"center"} sx={{ width: '100%', maxWidth: '100%' }} gap={2}>
        <Card onClick={() => {
          openInNewTab("https://www.upwork.com/freelancers/barnyak");
        }} className='portfolioCard' sx={{ padding: '20px', borderBottom: '4px solid #727D71' }}>
          <Typography fontSize="2.0rem">Upwork</Typography>
        </Card>
        <Card onClick={() => {
          openInNewTab("https://github.com/jtb9")
        }} className='portfolioCard' sx={{ padding: '20px', borderBottom: '4px solid #727D71' }}>
          <Typography fontSize="2.0rem">GitHub</Typography>
        </Card>
        <Card onClick={() => {
          openInNewTab("https://www.linkedin.com/in/justin-barnyak")
        }} className='portfolioCard' sx={{ padding: '20px', borderBottom: '4px solid #727D71' }}>
          <Typography fontSize="2.0rem">LinkedIn</Typography>
        </Card>
      </Stack>
    </Stack>
  }

  const renderPortfolioCard = (headline: string, imgSrcs: string[], label: string, key: string) => {
    let imageRenders: any[] = [];

    imgSrcs.forEach((img) => {
      imageRenders.push(<img src={img} alt="portfolio" />);
    });

    return <>
      <Stack key={key} direction="row" sx={{ width: '100%' }}>
        <Card onClick={() => {
          setPortfolioKey(key);
        }} className='portfolioCard' sx={{ padding: '20px', borderBottom: '4px solid #727D71', width: '100%' }}>
          <Stack justifyContent="center" sx={{ width: '100%', height: '100%' }} direction="row">
            <Stack justifyContent="center" sx={{ width: '100%', height: '100%', position: 'relative', overflow: 'hidden' }} direction={"column"}>
              <Typography fontSize="2rem">{headline} - </Typography>
              <Typography fontSize="1.2rem">{label}</Typography>
              <img style={{position: 'absolute', width: '200px', top: '0px', right: '0px'}} alt="portfolio thumbnail" src={imgSrcs[0]} />
            </Stack>
          </Stack>
        </Card>
      </Stack>
      <Dialog
        open={portfolioKey === key}
        onClose={() => {
          setPortfolioKey(undefined)
        }}

      >
        <Stack direction="column">
          {imageRenders}
          <Button onClick={() => {
            setPortfolioKey(undefined);
          }} variant="outlined" sx={{ width: '100%' }}>
            Done
          </Button>
        </Stack>
      </Dialog>
    </>
  }

  const renderPortfolio = () => {
    return <Stack direction="column" sx={{ marginTop: '30px' }}>
      <Typography sx={{ marginBottom: '30px' }} variant='h4'>Portfolio</Typography>
      <Stack direction="column" sx={{ width: '100%', maxWidth: '100%' }} gap={2}>
        {renderPortfolioCard("CLIENT", ["portfolio/manufacturing.png", "portfolio/manufacturing1.png", "portfolio/manufacturing2.png", "portfolio/manufacturing3.png", "portfolio/manufacturing4.png", "portfolio/manufacturing5.png"], "Manufacturing Portal", "mfturing")}
        {renderPortfolioCard("PROJECT", ["portfolio/barnyak.png", "portfolio/barnyak1.png"], "Barnyak Blog", "barnyakblog")}
        {renderPortfolioCard("CLIENT", ["portfolio/vleader.jpg", "portfolio/vleader1.png", "portfolio/vleader2.png", "portfolio/vleader3.png", "portfolio/vleader4.png", "portfolio/vleader5.png"], "vLeader", "vleader")}
        {renderPortfolioCard("PROJECT", ["portfolio/stonez1.png", "portfolio/stonez2.png"], "Stonez", "stonez")}
        {renderPortfolioCard("CLIENT", ["portfolio/chloes.png"], "Chloes Fudge", "chloes")}
        {renderPortfolioCard("PROJECT", ["portfolio/shapewars.png", "portfolio/shapewars1.png", "portfolio/shapewars2.png"], "Shape Wars", "shapewars")}
      </Stack>
    </Stack>
  }

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Stack sx={{ maxWidth: '800px', margin: 'auto', marginBottom: '80px' }} direction="column">
        {renderHeaderBackground()}

        {renderHero()}
        {renderContact()}
        {renderFeatureBox()}
        {renderFeatures()}
        {/* {renderBio()} */}
        {renderPortfolio()}
        {renderMedia()}
      </Stack>
    </Box>
  );
}

export default App;
